import { Box, Flex } from '@chakra-ui/react'

import { FC } from 'react'

import Navbar from '~/components/Navbar'
import KanbanBoard from '~/components/kanban/Board'
import { withPage } from '~/components/page'

const OCTPage: FC = () => {
	return (
		<Flex h='full' direction='column'>
			<Navbar />
			<Box flexGrow={1} bg='gray.100' px='4em'>
				<KanbanBoard leadTeam='oct' />
			</Box>
		</Flex>
	)
}

export default withPage(OCTPage, {
	public: false,
	name: 'OCT',
})
